<template>
  <!--
    <div class="card">
      <div class="card-header border-0 pt-3" style="text-align: center">
        <div class="card-title m-0">Otelcilik</div>
        <div class="d-flex align-items-center py-1">
          <a
            class="btn btn-sm btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#add_animal_tag_modal"
            id="kt_toolbar_primary_button"
          >
            Test
          </a>
        </div>
      </div>
    </div>
  -->
  <div class="row g-5 gx-xxl-8 mt-3">
    <div class="col-xxl-8">
      <div class="card card-xl-stretch-40 mb-8 cmb-xl-8">
        <div>
          <!--
            <div class="card-header border-0">
              <div class="card-title m-0">Modül Detayları</div>
            </div>
            -->
          <div class="dataTables_wrapper dt-bootstrap4 no-footer">
            <div class="card-body">
              <div
                class="symbol symbol-100px symbol-circle w-45px ms-2 mt-2"
                style="padding-right: 0px"
              >
                <img src="/media/images/2432846.png" class="p-1" />
              </div>
              <h1 class="mt-3 mb-6">
                {{ module.product.name }}
                <span class="ms-2 badge-light-success badge me-auto"
                  >{{ $t("modals.detail.current") }}</span
                >
              </h1>
              <h3>{{ $t("modals.detail.modalDescription") }}</h3>
              <p>
                {{ module.product.description }}
                Bu otelcilik modülü, otelcilikle ilgili gelen yorumları yapay
                zeka modeline ileterek onların değerlendirilmesini amaçlar.
                Gelen otel yorumlarını yazılı olarak yapay zekaya ileten bu
                modül, müşteri geri bildirimlerini etkin bir şekilde yönetmeyi
                sağlar. Yapay zeka algoritmaları, yorumları analiz ederek
                müşteri memnuniyetini artırmak için önemli içgörüler sunar ve
                otellerin hizmet kalitesini sürekli olarak iyileştirmelerine
                yardımcı olur. Bu sayede otelcilik işletmeleri, müşteri
                deneyimlerini daha etkin bir şekilde yönetebilir ve olumlu geri
                bildirimlere hızlı bir şekilde yanıt verebilirler.
              </p>
              <h3>{{ $t("modals.detail.packages") }}</h3>
              <hr>
              <div
                v-for="(productPackage, index) in module.product
                  .productPackages"
                :key="index"
              >
                <h5 class="pt-1">{{ productPackage.name }}</h5>
                <p>{{ productPackage.description }}</p>
                <div class="d-flex flex-column">
                  <span>{{ $t("modals.detail.numberOfUses") }}: {{ productPackage.quota }}</span>
                  <span class="mb-1"
                    >{{ $t("modals.detail.dayCount") }}: {{ productPackage.period }}</span
                  >
                </div>
                <!--begin::Stats-->
                <div
                  class="d-flex flex-wrap flex-grow-1 pb-1"
                  v-for="(
                    productModelSetting, index
                  ) in productPackage.productModelSettings"
                  :key="index"
                >
                  <span class="svg-icon svg-icon-4 svg-icon-success">
                    <inline-svg src="/media/icons/duotune/general/gen043.svg" />
                  </span>
                  <!--begin::Label-->
                  <div class="fw-bold text-success text-center">
                    {{ productModelSetting.name }}
                  </div>
                  <!--end::Label-->
                </div>
                <!--end::Stats-->
                <hr>
              </div>
              <h3>{{ $t("modals.detail.FAQS") }}</h3>
              <template
                v-for="(faq, index) in module.product.productFAQs"
                :key="index"
              >
                <div class="border rounded mb-3 mt-4">
                  <div
                    class="card-header align-items-center rounded"
                    role="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#qa_manual' + index"
                    aria-expanded="false"
                    :aria-controls="'qa_manual' + index"
                  >
                    <h3 class="card-title align-items-start flex-column">
                      <span class="fw-bolder text-dark">{{
                        faq.question
                      }}</span>
                    </h3>

                    <!--end::Content-->
                  </div>
                  <div>
                    <div :id="'qa_manual' + index" class="collapse">
                      <!--begin::Card body-->
                      <div class="d-flex flex-wrap align-items-center">
                        <div class="card-body pt-5">
                          <!--begin::Item-->
                          <div class="mb-2 d-flex align-items-sm-center">
                            <!--begin::Title-->
                            <div
                              class="
                                d-flex
                                flex-row-fluid flex-wrap
                                align-items-center
                              "
                            >
                              <div class="flex-grow-1 me-2">
                                <a class="text-gray-800 fw-bold fs-7"
                                  >{{ faq.answer }}
                                </a>
                              </div>
                            </div>
                            <!--end::Title-->
                          </div>
                          <!--end::Item-->
                        </div>
                      </div>
                      <!--end::Card body-->
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-4">
      <div class="card card-xl-stretch-40 mb-8 cmb-xl-8">
        <div>
          <div class="card-header border-0">
            <div
              class="
                d-flex
                align-items-center
                flex-wrap
                fs-3
                justify-content-center
                flex-grow-1
              "
            >
              <!--begin::Label-->
              <div class="fw-bold fs-1 text-center">
                <div class="card-title m-0">{{ $t("modals.detail.packages") }}</div>
              </div>
              <!--end::Label-->
            </div>
          </div>
          <div class="dataTables_wrapper dt-bootstrap4 no-footer">
            <div
              class="
                d-flex
                align-items-center
                flex-wrap
                mb-5
                mt-auto
                fs-6
                justify-content-center
                flex-grow-1
              "
            >
              <div class="d-flex overflow-auto h-50px">
                <ul
                  class="
                    nav nav-stretch nav-line-tabs nav-line-tabs-2x
                    border-transparent
                    fs-5
                    fw-bolder
                    flex-nowrap
                  "
                >
                  <!--begin::Nav item-->
                  <li
                    class="nav-item"
                    v-for="(productPackage, index) in module.product
                      .productPackages"
                    :key="index"
                  >
                    <a
                      class="
                        nav-link
                        justify-content-center
                        text-active-gray-800
                      "
                      :class="{ active: activeTab === index }"
                      @click="activeTab = index"
                      role="tab"
                      href="javascript:void(0)"
                    >
                      {{ productPackage.name }}
                    </a>
                  </li>
                  <!--end::Nav item-->
                </ul>
              </div>
            </div>

            <div
              class="card-body pt-0"
              v-for="(productPackage, index) in module.product.productPackages"
              :key="index"
              v-show="activeTab === index"
            >
              <!--begin::Stats-->
              <div
                class="
                  d-flex
                  align-items-center
                  flex-wrap
                  fs-6
                  justify-content-center
                  flex-grow-1
                  mt-4
                "
                :id="productPackage.name"
              >
                <!--begin::Label-->
                <div class="fw-bold fs-1 text-center">
                  <h1 style="font-size: 28px">
                    {{ productPackage.name }}
                  </h1>
                </div>

                <!--end::Label-->
              </div>
              <!--end::Stats-->

              <!--begin::Stats-->
              <div
                class="
                  d-flex
                  align-items-center
                  flex-wrap
                  mb-2
                  mt-auto
                  fs-6
                  justify-content-center
                  flex-grow-1
                "
              >
                <!--begin::Label-->
                <div class="fw-bold text-gray-800 text-center mt-2">
                  {{ productPackage.description }}
                </div>
                <!--end::Label-->
              </div>
              <!--end::Stats-->

              <!--begin::Stats-->
              <div
                class="
                  d-flex
                  align-items-center
                  flex-wrap
                  mt-auto
                  fs-7
                  justify-content-center
                  flex-grow-1
                "
              >
                <!--begin::Label-->
                <div class="fw-bold text-gray-700 text-center mt-2">
                  {{ $t("modals.detail.numberOfUses") }}: {{ productPackage.quota }}
                </div>
                <!--end::Label-->
              </div>
              <!--end::Stats-->

              <!--begin::Stats-->
              <div
                class="
                  d-flex
                  align-items-center
                  flex-wrap
                  mb-2
                  mt-auto
                  fs-7
                  justify-content-center
                  flex-grow-1
                "
              >
                <!--begin::Label-->
                <div class="fw-bold text-gray-700 text-center mt-1">
                  {{ $t("modals.detail.dayCount") }}: {{ productPackage.period }}
                </div>
                <!--end::Label-->
              </div>
              <!--end::Stats-->

              <!--begin::Stats-->
              <div
                class="
                  d-flex
                  align-items-center
                  flex-wrap
                  mt-auto
                  fs-8
                  pt-1
                  justify-content-center
                  flex-grow-1
                "
                v-for="(
                  productModelSetting, index
                ) in productPackage.productModelSettings"
                :key="index"
              >
                <span class="svg-icon svg-icon-4 svg-icon-success">
                  <inline-svg src="/media/icons/duotune/general/gen043.svg" />
                </span>
                <!--begin::Label-->
                <div class="fw-bold text-success text-center">
                  {{ productModelSetting.name }}
                </div>
                <!--end::Label-->
              </div>
              <!--end::Stats-->

              <div
                class="
                  d-flex
                  align-items-center
                  flex-wrap
                  mt-3
                  justify-content-center
                  flex-grow-1
                "
              >
                <!--begin::Button-->
                <a
                  v-if="!productPackage.purchased"
                  class="btn btn-md"
                  :class="[
                    'btn',
                    'btn-md',
                    productPackage.price === 0 ? 'btn-success' : 'btn-primary',
                  ]"
                  style="align-items: center; width: 90%"
                  @click="
                    productPackage.price == 0
                      ? buyModule(Number(productPackage.id))
                      : null
                  "
                  :disabled="productPackage.price !== 0"
                >
                  {{
                    productPackage.price === 0
                      ? $t("modals.detail.free")
                      : productPackage.price + " TL"
                  }}
                </a>
                <a v-else>
                  <router-link
                    to="/hotelManagement"
                    class="btn btn-md btn-secondary"
                    style="align-items: center"
                  >
                  {{ $t("modals.detail.purchased") }}
                  </router-link>

                </a>
                <!--end::Button-->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card card-xl-stretch-40 mb-8 cmb-xl-8" v-show="module.product.productPackages[activeTab].purchased === true">
    <!-- Modül Ayarları Card -->
      <div class="card-header border-0">
        <div
          class="d-flex align-items-center flex-wrap fs-3 justify-content-center flex-grow-1"
        >
          <!--begin::Label-->
          <div class="fw-bold fs-1 text-center">
            <div class="card-title m-0">{{ $t("modals.detail.settings") }}</div>
          </div>
          <!--end::Label-->
        </div>
      </div>
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div
          class="card-body pt-0"
          v-for="(productPackage, index) in module.product.productPackages"
          :key="index"
          v-show="activeTab === index && productPackage.purchased"
        >
          <!-- Product Package Details -->

          <!-- Buy/Activate Button -->
          <div
            class="d-flex align-items-center flex-wrap justify-content-center flex-grow-1"
          >
            <!--begin::Button-->
              <div class="fv-row mb-4">
                <label
                  class="form-label fs-6 fw-bolder text-dark mt-3"
                  >{{ $t("modals.detail.activeAiModel") }}</label
                >
                <div class="d-flex align-items-center">
                  <select
                    class="form-select form-select-sm form-select-solid"
                    required
                    @change="handleModelChange"
                  >
                    <option
                      v-for="settings in productPackage.productModelSettings"
                      :key="settings.id"
                      :value="settings.id"
                      :selected="settings.selected"
                    >
                      {{ settings.name }}
                    </option>
                  </select>
                </div>
              </div>
            <!--end::Button-->
          </div>
        </div>
      </div>
    </div>

      <div class="card card-xl-stretch-40 mb-8 cmb-xl-8">
        <div class="card-header border-0">
          <div
            class="
              d-flex
              align-items-center
              flex-wrap
              fs-6
              justify-content-center
              flex-grow-1
            "
          >
            <!--begin::Label-->
            <div class="fw-bold fs-1 text-center">
              <div class="card-title m-0">{{ $t("modals.detail.details.title") }}</div>
            </div>
            <!--end::Label-->
          </div>
        </div>
        <div class="dataTables_wrapper dt-bootstrap4 no-footer">
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-md-6">
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-125px
                    py-3
                    px-4
                    me-3
                    mb-3
                  "
                >
                  <div class="fs-6 text-gray-800 fw-bolder">{{ $t("modals.detail.details.update") }}</div>
                  <div class="fw-bold text-gray-400">{{ $t("modals.detail.details.lifeLong") }}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-125px
                    py-3
                    px-4
                    me-3
                    mb-3"
                >
                  <div class="fs-6 text-gray-800 fw-bolder">{{ $t("modals.detail.details.techSupport") }}</div>
                  <div class="fw-bold text-gray-400">{{ $t("modals.detail.details.lifeLong") }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-125px
                    py-3
                    px-4
                    me-3
                    mb-3
                  "
                >
                  <div class="fs-6 text-gray-800 fw-bolder">{{ $t("modals.detail.details.licence") }}</div>
                  <div class="fw-bold text-gray-400">{{ $t("modals.detail.details.full") }}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-125px
                    py-3
                    px-4
                    me-3
                    mb-3
                  "
                >
                  <div class="fs-6 text-gray-800 fw-bolder">{{ $t("modals.detail.details.installation") }}</div>
                  <div class="fw-bold text-gray-400">{{ $t("modals.detail.details.oneClick") }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--end::Basic info-->
</template>

<style lang="scss">
.aside-menu .menu .menu-sub .menu-item a a.menu-link {
  padding-left: calc(0.75rem + 25px);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  transition: none;
  outline: none !important;
}

.aside-menu .menu .menu-sub .menu-sub .menu-item a a.menu-link {
  padding-left: calc(1.5rem + 25px);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  transition: none;
  outline: none !important;
}

table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable > thead {
  th.sorting {
    position: relative;
  }
  .sorting:after {
    position: absolute;
  }
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: none;
  border-radius: 0.475rem;
  font-weight: 500;
  font-size: 1.075rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009ef7;
}
</style>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { PAGE_TITLE_PATH } from "@/presentation/constants/PageTitle";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import store from "@/presentation/store";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRoute, useRouter } from "vue-router";
import { ProductListModel } from "@/domain/product/productList/model/ProductListModel";
import { BuyProductModel } from "@/domain/product/buyProduct/model/BuyProductModel";
import Swal from "sweetalert2";
import { UpdateProductModelSettingModel } from "@/domain/product/updateProductModelSetting/model/UpdateProductModelSettingModel";
import { MenuComponent } from "@/presentation/assets/ts/components";

export default defineComponent({
  name: "Profilim",
  components: { Field, Form, ErrorMessage },
  data() {
    return {
      activeTab: 0, // Default olarak ilk sekmeyi seçili yap
    };
  },
  methods: {
    handleModelChange(event) {
      const selectedModelSettingId = event.target.value;
      this.updateProductModelSetting(Number(selectedModelSettingId));
    },
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const swalNotification = new SwalNotification();

    const boughtModule = ref<boolean>(false);

    const scrollElRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);

    const productController = store.state.ControllersModule.productController;
    const module = ref<ProductListModel>({
      product: {
        productPackages: [
          {
            purchased: false,
            productModelSettings: []
          }
        ],
      },
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else return text;
    };

    const getModule = async () => {
      const productListModel: ProductListModel = {
        product: {
          productPackages: [],
        },
        locale: "tr",
      };

      productController
        .productList(productListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((product) => {
              if (product.product.id == Number(route.params["id"]))
                module.value = product;
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const buyModule = async (productPackage: number) => {
      const buyProductModel: BuyProductModel = {
        productPackageId: productPackage,
      };

      productController
        .buyProduct(buyProductModel)
        .then((response) => {
          if (response.isSuccess) {
            boughtModule.value = true; 
            console.log("ilk öncesi", store.getters.buyModuleCount)
            store.dispatch("buyModuleCount", boughtModule.value);
            console.log("sonrası", store.getters.buyModuleCount)

            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_BUY_PRODUCT),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("common.button.ok"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
          //    router.push({ name: "hotelManagement" });
           location.reload()
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const updateProductModelSetting = async (productModelSetting: number) => {
      console.log("productModelSetting", productModelSetting);
      const updateProductModelSettingModel: UpdateProductModelSettingModel = {
        productId: Number(route.params["id"]),
        productModelSetting: productModelSetting,
      };

      productController
        .updateProductModelSetting(updateProductModelSettingModel)
        .then((response) => {
          if (response.isSuccess) {
            Swal.fire({
              text: t(
                SWAL_MESSAGES.SUCCESS.SUCCESS_UPDATE_PRODUCT_MODEL_SETTING
              ),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("common.button.ok"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              router.push({ name: "hotelManagement" });
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.detail.pageTitle"));
      getModule();

      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    return {
      PAGE_TITLE_PATH,
      translate,
      module,
      buyModule,
      updateProductModelSetting,
    };
  },
});
</script>
